// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard/[...].js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-dashboard-music-index-js": () => import("./../../../src/pages/dashboard/music/index.js" /* webpackChunkName: "component---src-pages-dashboard-music-index-js" */),
  "component---src-pages-dashboard-music-js": () => import("./../../../src/pages/dashboard/music/[...].js" /* webpackChunkName: "component---src-pages-dashboard-music-js" */),
  "component---src-pages-guilds-js": () => import("./../../../src/pages/guilds.js" /* webpackChunkName: "component---src-pages-guilds-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("./../../../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

