import React, { useState, useEffect } from "react"
import IsAuthenticated, { Login, Logout, GetMutualGuilds } from "../services/auth"


const defaultState = {
    isLogged: false,
    me: { mutualGuilds: [] },
    login: () => { },
    logout: () => { },
    refreshMutual: () => { }
}

const AuthContext = React.createContext(defaultState)


function AuthProvider({ children }) {
    const { isLogged, me, setLogged, setMe } = IsAuthenticated()
    const [mutual, setMutual] = useState([]);
    const [dispatchState, setDispatchState] = useState({
        login: false,
        logout: false,
        refreshMutual: true //refresh mutual on first load
    });

    //LOGOUT STATE CALLED
    useEffect(() => {
        if (!dispatchState.logout) return
        Logout();
        setLogged(false); setMe({});
        setDispatchState({...dispatchState, logout: false});
    }, [dispatchState.logout])

    //MUTUAL STATE CALLED
    useEffect(() => {
        if (!dispatchState.refreshMutual) return
        GetMutualGuilds(setMutual);
        setDispatchState({...dispatchState, refreshMutual: false});
    }, [dispatchState.refreshMutual])

    const me_compiler = me;
    me_compiler.mutualGuilds = mutual;

    return (
        <AuthContext.Provider
            value={{
                isLogged,
                me: me_compiler,
                login: Login,
                logout: () => setDispatchState({...dispatchState, logout: true}),
                refreshMutual: () => setDispatchState({...dispatchState, refreshMutual: true})
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext

export { AuthProvider }