import { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
query Getauthurl {
    site {
        siteMetadata {
          api {
            main_url
            default_version
            me_uri
            login_uri
          }
        }
      }
    }
`

const BASE_API_ROUTE = process.env.GATSBY_BASE_API_ROUTE || `https://api.eevnxx.tk/v3`;

const IsAuthenticated = () => {
  const url = BASE_API_ROUTE + `/discord/me`;

  const [isLogged, setLogged] = useState(false);
  const [me, setMe] = useState({});

  useEffect(() => {
    fetch(url, {
      credentials: 'include'
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) { setLogged(true) }
        else { setLogged(false) }
        return res
      })
      .then(response => response.json())
      .then(resultData => {
        setMe(resultData)
      })
  }, []);
  return { isLogged, me, setLogged, setMe };
}

const Logout = () => {
  // const data = useStaticQuery(query)
  // const def_url = (api) => `${api.main_url}${api.default_version}${api.me_uri}`
  // const url = def_url(data.site.siteMetadata.api)

  const url = BASE_API_ROUTE + '/discord/me';

    fetch(url, {
      credentials: 'include',
      method: 'DELETE'
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {/* not logged already */ }
        else {/* logout success */ }
        return res
      })
      .then(response => response.json())
      .then(resultData => {return resultData})
}

const Login = () => {
  // const data = useStaticQuery(query)
  // const def_url = (api) => `${api.main_url}${api.default_version}${api.login_uri}`
  // const url = def_url(data.site.siteMetadata.api)

  if (typeof window !== `undefined`) window.location.href = BASE_API_ROUTE + '/auth/discord';
}

const GetMutualGuilds = (setMutual) => {
  // const data = useStaticQuery(query)
  // const def_url = (api) => `${api.main_url}${api.default_version}${api.me_uri}`
  // const url = def_url(data.site.siteMetadata.api)

  const url = BASE_API_ROUTE + '/discord/guilds';

    fetch(url, {
      credentials: 'include'
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) { /*'auth error'*/ }
        return res
      })
      .then(response => response.json())
      .then(resultData => setMutual(resultData));
}

export default IsAuthenticated

export { Login, Logout, GetMutualGuilds }