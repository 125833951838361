/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Import all js dependencies.
// import "@popperjs/core/dist/umd/popper.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./src/styles/global.css"

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

// or:
// require('./src/styles/global.css')

import React from "react"
import { AuthProvider } from "./src/context/AuthContext"

export const wrapRootElement = ({ element }) => (
    <AuthProvider>{element}</AuthProvider>
)